// AJAX
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

// Datatables
if (typeof($.fn.dataTable) !== 'undefined') {

    $.extend(true, $.fn.dataTable.defaults, {
        language: {
            url: '/json/' + Lang.getLocale() + '/jquery.dataTables.json'
        },
        infoCallback: function(oSettings, iStart, iEnd, iMax, iTotal) {

			return editInfoMessage(iStart, iEnd, iMax, iTotal);
		}
    });
}

// Fix résultats filtrés sur datatables
window.editInfoMessage = function(start, end, max, total) {

    if (total == 0) {

        if (total < max) {

            var message = "Affichage de l'&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ment";
        }
        else {

            var message = "Aucun r&eacute;sultat";
        }
    }
    else {

        var message = "Affichage de l'&eacute;l&eacute;ment " + numberWithSpaces(start) + " &agrave; " + numberWithSpaces(end) + " sur " + numberWithSpaces(total) + (total > 1 ? " &eacute;l&eacute;ments" : " &eacute;l&eacute;ment");
    }

    if (total < max) {

        message += " (filtr&eacute; de " + numberWithSpaces(max) + (max > 1 ? " &eacute;l&eacute;ments" : " &eacute;l&eacute;ment") + " au total)";
    }

    return message;
}

// Datetimepicker
window.setStartDate = function(startElt, endElt) {

    var dateFin = endElt.data('DateTimePicker').date();

    if (dateFin) {

        var dateDebut = startElt.data('DateTimePicker').date();

        if (dateDebut && dateFin.isBefore(dateDebut)) {

            startElt.data('DateTimePicker').date(dateFin);
        }
    }
}

window.setEndDate = function(startElt, endElt) {

    var dateDebut = startElt.data('DateTimePicker').date();

    if (dateDebut) {

        var dateFin = endElt.data('DateTimePicker').date();

        if (dateFin && dateDebut.isAfter(dateFin)) {

            endElt.data('DateTimePicker').date(dateDebut);
        }
    }
}

// Disable elements
window.disableElements = function(elements, loader) {

    loader = typeof loader !== 'undefined' ? loader : true;

    elements.filter(':focus').blur();

    elements.each(function() {

        $(this).addClass('disabled');

        if ($(this).is('a, area, link, button, input, select, textarea, optgroup, option, fieldset')) {

            $(this).attr('disabled', true);
        }

        if (loader) {

			$(this).html('<i class="fa fa-fw fa-spinner fa-pulse"></i><span class="sr-only loading-text">' + $(this).html() + '</span>');
        }
    });
}

// Enable elements
window.enableElements = function(elements, loader) {

    loader = typeof loader !== 'undefined' ? loader : true;

    elements.each(function() {

        if (loader) {

			$(this).html($(this).find('.loading-text').html());
        }

        if ($(this).is('a, area, link, button, input, select, textarea, optgroup, option, fieldset')) {
            
            $(this).attr('disabled', false);
        }

        $(this).removeClass('disabled');
    });
}

// Formatage de nombres avec une virgule
window.numberWithSpaces = function(number) {

    var parts = number.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
}

$(document).ready(function() {
	
	// Fix readonly
	$('body').on('click', '.readonly', function() {

	    return false;
	});

	// Disable submit button on form sending
	$('body').on('submit', 'form', function() {

		disableElements($(this).find('.send-form'));
	});
});
