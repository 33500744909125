/* jquery */
global.$ = global.jQuery = require('jquery');

/* bootstrap */
require('popper.js');
require('bootstrap');

// for supporting Object.keys not compatible in IE
import 'core-js/stable';
import 'regenerator-runtime/runtime';

/* actual (sidebar) */
global.actual = require('actual');

/* sprintf (POedit) */
require('sprintf-js');

/* sweet alert */
import Swal from 'sweetalert2';
global.swal = Swal;

/* datatables */
require('datatables.net-bs4');
require('datatables.net-responsive-bs4');

/* helpers */
require('./utils/helpers');

/* notifications */
require('./sw/notifications');

/* sidebar */
require('./partials/sidebar');
