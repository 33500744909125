$(document).ready(function() {

	// Sidebar
	var sideBar = $('#sidebar');

	if (sideBar.length > 0) {

		var toggleSideBar = $('#toggle-sidebar');
		var sideBarElements = sideBar.add('[role="main"]').add(toggleSideBar);

		toggleSideBar.on('click', function() {

			if (sideBar.hasClass('expend')) {

				if (actual('width', 'px') < 992) {

					sideBarElements.addClass('show').removeClass('expend');
				}
				else {

					sideBarElements.removeClass('expend');
				}
			}
			else if (sideBar.hasClass('show')) {

				sideBarElements.removeClass('show');
			}
			else {

				sideBarElements.addClass('show');
			}
		});

		$('#sidebar .nav-link:not([href=""]) .dropdown, #sidebar .nav-link[href=""]:has(.dropdown)').on('click', function(e) {

			e.preventDefault();

			var parent = $(this).closest('.nav-item');

			if (sideBar.hasClass('show') || sideBar.hasClass('expend')) {

				parent.hasClass('expend') ? parent.removeClass('expend') : parent.addClass('expend');
			}
			else {

				parent.addClass('expend');
				toggleSideBar.click();
			}
		});
	}
});
