function registerServiceWorker() {

	return navigator.serviceWorker.register('/service-worker.min.js')
	.then(function(registration) {

		console.log('Service worker ready');
		_registration = registration;
		askPermission();

		return registration;

	}).catch(function(e) {

		console.log('Registration failed with ' + e);
	});
}

function askPermission() {

	return new Promise(function(resolve, reject) {

		const permissionResult = Notification.requestPermission(function(result) {

			resolve(result);
		});

		if (permissionResult) {

			permissionResult.then(resolve, reject);
		}

	})
	.then(function (permissionResult) {

		if (permissionResult !== 'granted') {

			throw new Error('We weren\'t granted permission');
		} 
		else {
					
			subscribeUserToPush();
		}
	});
}

function urlBase64ToUint8Array(base64String) {

	const padding = '=' . repeat((4 - base64String.length % 4) % 4);
	const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; ++i) {

		outputArray[i] = rawData.charCodeAt(i);
	}

	return outputArray;
}

function getSWRegistration() {

	var promise = new Promise(function(resolve, reject) {

		// Do a thing, possibly async, then…

		if (_registration != null) {

			resolve(_registration);
		} 
		else {

			reject(Error('It broke'));
		}
	});

	return promise;
}

function subscribeUserToPush() {

	getSWRegistration()
	.then(function(registration) {

		console.log(registration);

		const subscribeOptions = {
			userVisibleOnly: true,
			applicationServerKey: urlBase64ToUint8Array(VAPID)
		};

		return registration.pushManager.subscribe(subscribeOptions);

	})
	.then(function (pushSubscription) {

		console.log('Received PushSubscription : ', JSON.stringify(pushSubscription));
		sendSubscriptionToBackEnd(pushSubscription);

		return pushSubscription;
	});
}

function sendSubscriptionToBackEnd(subscription) {

	return fetch(route('back.sw.save_subscription_push', {id: UserId}, false).toString(), {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(subscription)
	})
	.then(function(response) {

		if (!response.ok) {

			throw new Error('Bad status code from server');
		}

		return response.json();
	})
	.then(function(responseData) {

		if (!(responseData.success)) {

			throw new Error('Bad response from server');
		}
	});
}

typeof VAPID !== 'undefined' && typeof UserId !== 'undefined' && 'serviceWorker' in navigator && window.addEventListener('load', function() {

	var _registration = null;
	registerServiceWorker();
});
